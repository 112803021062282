function intersperse(arr, value) {
    return arr.reduce((result, item, index) => {
        if (index > 0) {
            result.push(value);
        }
        result.push(item);
        return result;
    }, []);
}
function randomItem(array) {
    const index = Math.floor(Math.random() * array.length);
    return array[index];
}
function isSuperset(superset, subset) {
    return superset === subset || subset.every(item => superset.includes(item));
}
function arrayFirst(arr) {
    return arr[0];
}
function arrayLast(arr) {
    return arr[arr.length - 1];
}
export const Array = {
    intersperse,
    randomItem,
    isSuperset,
    first: arrayFirst,
    last: arrayLast,
};
