import { Client, Constants, Record, RecordMode, RecordPermissions, RecordSource, } from "@baqhub/sdk";
//
// API.
//
function buildAuthenticatedIdentity(authState) {
    const client = Client.authenticated(authState);
    const findClient = () => client;
    const blobUrlBuilder = client.blobUrlBuilderFor(authState.entityRecord);
    return {
        isAuthenticated: true,
        entityRecord: Record.toSelf(authState.entityRecord),
        findClient,
        blobUrlBuilder,
        discover: client.discover,
        downloadBlob: client.downloadBlob,
    };
}
function buildUnauthenticatedIdentity() {
    const entity = "never.baq.dev";
    const date = new Date("2024-01-01T01:01:00.000Z");
    const clients = new Map();
    const findClient = e => {
        if (e === entity) {
            throw new Error("Authenticated Store required.");
        }
        const existingClient = clients.get(e);
        if (existingClient) {
            return existingClient;
        }
        const newClient = Client.ofEntity(e);
        clients.set(e, newClient);
        return newClient;
    };
    const blobUrlBuilder = (record, blob, expiresInSeconds) => {
        const client = findClient(record.author.entity);
        const entityRecord = client.getEntityRecordSync();
        const builder = client.blobUrlBuilderFor(entityRecord);
        return builder(record, blob, expiresInSeconds);
    };
    const discover = (entity, signal) => {
        const client = findClient(entity);
        return client.getEntityRecord(signal);
    };
    const downloadBlob = (record, blob, signal) => {
        const client = findClient(record.author.entity);
        return client.downloadBlob(record, blob, signal);
    };
    return {
        isAuthenticated: false,
        entityRecord: {
            author: { entity },
            id: "00000000000000000000000000000000",
            source: RecordSource.SELF,
            createdAt: date,
            receivedAt: date,
            version: {
                author: { entity },
                createdAt: date,
                receivedAt: date,
                hash: "0000000000000000000000000000000000000000000000000000000000000000",
            },
            type: {
                entity: Constants.typesEntity,
                recordId: "80be958368dd414fabb9420647daa1ec",
                versionHash: "5869ed5eb6b565b92990ecfda31b4eb7e837489cb4799a534c00e3fd6ca756e9",
            },
            content: {
                previousEntities: [],
                profile: {},
                servers: [],
            },
            mode: RecordMode.SYNCED,
            permissions: RecordPermissions.public,
        },
        findClient,
        blobUrlBuilder,
        discover,
        downloadBlob,
    };
}
export const StoreIdentity = {
    new: buildAuthenticatedIdentity,
    newUnauthenticated: buildUnauthenticatedIdentity,
};
