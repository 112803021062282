import isDate from "lodash/isDate.js";
//
// I/O.
//
function queryDateToString(queryDate) {
    if (isDate(queryDate)) {
        return `"${queryDate.toISOString()}"`;
    }
    return `"${queryDate[0].toISOString()}"+${queryDate[1]}`;
}
function dateCompare(date1, date2) {
    if (date1 > date2) {
        return 1;
    }
    if (date1 < date2) {
        return -1;
    }
    return 0;
}
function stringCompare(string1, string2) {
    if (string1 === string2) {
        return 0;
    }
    if (string1 > string2) {
        return 1;
    }
    return -1;
}
function queryDateCompare(recordDate, recordId, queryDate) {
    if (!recordDate || !queryDate) {
        return 0;
    }
    // Only date.
    if (isDate(queryDate)) {
        return dateCompare(recordDate, queryDate);
    }
    // Date + VersionHash.
    const dateResult = dateCompare(recordDate, queryDate[0]);
    if (recordId && dateResult === 0) {
        return stringCompare(recordId, queryDate[1]);
    }
    return dateResult;
}
export const QueryDate = {
    toString: queryDateToString,
    compare: queryDateCompare,
};
